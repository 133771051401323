<template>
  <div class="search_page">
    <div class="top_holder"></div>
    <!-- 搜索框 -->
    <div class="search">
      <div class="search_left">
        <input
          type="text"
          class="ipt"
          v-model="keyword"
          @keyup.enter="search"
          @focus="changeIptColor"
          @blur="removeIptColor"
          placeholder="请输入关键词"
        /><a-icon style="font-size: 20px" type="close" @click="keyword = ''" />
      </div>
      <div @click="search" class="search_btn">搜索</div>
    </div>
    <!-- 搜索热词 -->
    <div class="search_hot_word">
      <span class="hot_word_left"><a-icon type="fire" />热门搜索：</span>
      <span
        class="hot_word_item"
        @click="searchHotword(item)"
        v-for="item in hotword"
        :key="item"
        >{{ item }}</span
      >
    </div>
    <!-- 搜索结果导航 -->
    <ul class="search_result_nav web-center">
      <li
        class="search_result_nav_item"
        :class="type == 'all' ? 'active' : ''"
        @click="type = 'all'"
        style="margin-left: 80px"
      >
        全部
      </li>
      <li
        class="search_result_nav_item"
        :class="type == 'plugin' ? 'active' : ''"
        @click="type = 'plugin'"
      >
        插件
      </li>
      <li
        class="search_result_nav_item"
        :class="type == 'nav' ? 'active' : ''"
        @click="type = 'nav'"
      >
        网站
      </li>
    </ul>
    <!-- 搜索结果展示 -->
    <div
      class="list_content web-center"
      v-if="(type == 'all' || type == 'plugin') && listData.length"
    >
      <div class="list">
        <p class="list_title">应用插件</p>
        <List :listData="listData" :column="4"></List>
      </div>
    </div>
    <div
      class="list_content web-center"
      v-if="(type == 'all' || type == 'nav') && navListData.length"
    >
      <div class="list">
        <p class="list_title">跨境网站</p>
        <CrossNavList :listData="navListData" :column="4"></CrossNavList>
      </div>
    </div>
    <!-- 空白页 -->
    <div class="noResult" v-show="!listData.length && !navListData.length">
      <img
        src="@/assets/img/extension/search_noResult.png"
        alt=""
        class="img"
      />
      <p class="noResult_title">没有找到关于“亚马逊”的内容</p>
      <p class="noResult_desc">
        您可以换个关键词继续搜索 或
        <span style="color: #4c84ff; cursor: pointer" @click="submitEx"
          >点我提交插件</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import List from "../components/List.vue";
import CrossNavList from "../components/CrossNavList.vue";
import { crossNavCategory, crossNavList, pluginList } from "@/api/extension.js";
export default {
  components: { List, CrossNavList },
  data() {
    return {
      keyword: "",
      type: "all",
      hotword: [
        "亚马逊",
        "领星",
        "运营参谋",
        "翻译",
        "卖家精灵",
        "店铺运营",
        "产品分析",
        "关键词分析",
      ],
      listData: [],
      navListData: [],
    };
  },
  created() {
    this.keyword = this.$route.query.keyword;
    this.type = this.$route.query.type || "all";
    this.getPluginList();
    this.getNavList();
  },
  beforeRouteUpdate(to, from, next) {
    this.keyword = to.query.keyword;
    this.getPluginList();
    this.getNavList();
    next();
  },
  methods: {
    getPluginList() {
      pluginList({ title: this.keyword, show_install: 0 }).then((res) => {
        const list = res.data.data.list;
        this.listData = list;
      });
    },
    getNavList() {
      crossNavList({ title: this.keyword }).then((res) => {
        const list = res.data.data.list;
        this.navListData = list;
      });
    },
    changeIptColor(e) {
      e.target.parentNode.style.borderColor = "#4C84FF";
    },
    removeIptColor(e) {
      e.target.parentNode.style.borderColor = "#abb4c3";
    },
    search() {
      this.$router.push({
        path: "/extension/search",
        query: { keyword: this.keyword },
      });
    },
    searchHotword(item) {
      this.keyword = item;
      this.search();
    },
    submitEx() {
      this.$message.warning("功能暂未开放");
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.search_page {
  .top_holder {
    height: 60px;
    background-color: #fff;
  }
  .search {
    width: 800px;
    height: 60px;
    margin: 40px auto 20px;
    border-radius: 4px;
    background-color: #fff;
    .flex();
    .search_left {
      width: 650px;
      height: inherit;
      padding: 15px 20px;
      border: 1px solid #abb4c3;
      border-right: 0px solid;
      border-radius: 4px 0px 0px 4px;
      .flex();
      .ipt {
        height: 100%;
        width: 540px;
      }
    }
    .search_btn {
      width: 150px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      background: #4c84ff;
      border-radius: 0px 4px 4px 0px;
    }
  }
  .search_hot_word {
    width: 800px;
    margin: 20px auto 60px;
    color: #616b8b;
    .flex(flex-start);
    .hot_word_item {
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: @blue;
      }
    }
  }
  .search_result_nav {
    width: 1200px;
    height: 60px;
    background: #4c84ff;
    border-radius: 4px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 25px;
    .flex(flex-start);
    .search_result_nav_item {
      padding: 0 12px;
      margin-right: 60px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
      &.active {
        color: #fff;
        border-bottom: 2px solid #fff;
      }
    }
  }
  .list_content {
    .list {
      .list_title {
        margin: 40px 0 20px;
        font-size: 20px;
        font-weight: 600;
        color: #2c354b;
        line-height: 28px;
      }
    }
  }
  .noResult {
    width: 100%;
    height: 642px;
    .flex(center;center;@direction: column);
    .img {
      width: 280px;
      height: 142px;
    }
    .noResult_title {
      margin: 12px auto;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #374567;
      line-height: 25px;
    }
    .noResult_desc {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #374567;
      line-height: 22px;
    }
  }
}
</style>