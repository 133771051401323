<template>
	<div>
		<div class="list">
			<div
				class="list_item"
				:style="{
					width: width,
					marginRight: (index + 1) % column == 0 ? '0px' : '20px',
				}"
				v-for="(item, index) in listData"
				@click="goTo(item.navigation_url)"
				:key="item.id"
			>
				<div class="list_item_top">
					<img :src="item.icon_url" alt="" class="icon" />
					<div class="info">
						<p class="title">{{ item.title }}</p>
						<p class="source">提供方：{{ item.provider }}</p>
					</div>
				</div>
				<p class="list_item_desc">{{ item.description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CrossNavList',
	components: {},
	props: ['listData'],
	data() {
		return {
			column: 4,
			width: '',
		};
	},
	created() {
		const offsetW = (this.column - 1) * 20 + 'px';
		this.width = `calc((100% - ${offsetW}) / ${this.column})`;
	},
	methods: {
		addExtension(item) {
			this.extensionId = item.id;
			this.visible = true;
			this.isInstall = item.is_install;
		},
		goTo(url) {
			window.open(url.trim());
		},
	},
};
</script>

<style lang="less" scoped>
@import '@/style/mixin.less';
.list {
	.flex(@justify:flex-start;@wrap:wrap);
	.list_item {
		padding: 20px;
		margin-bottom: 20px;
		border-radius: 4px;
		cursor: pointer;
		background-color: #fff;
		transition: all 0.3s;
		&:hover {
			background-color: #f7f9fd;
			transform: translateY(-2px);
			.list_item_top {
				.info {
					.title {
						color: #4c84ff;
					}
				}
			}
		}
		.list_item_top {
			.flex(@justify:flex-start);
			.icon {
				width: 40px;
				height: 40px;
				margin-right: 20px;
			}
			.info {
				overflow: hidden;
				.title {
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #374567;
					line-height: 22px;
					transition: all 0.3s;
					.ellipsis();
				}
				.source {
					margin-top: 5px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #878fa7;
					line-height: 17px;
					.ellipsis();
				}
			}
		}
		.list_item_desc {
			margin: 15px 0 0px;
			height: 36px;
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 18px;
			.ellipsis-multi();
		}
	}
}
</style>
